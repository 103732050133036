import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SecondaryHeader from "../components/SecondaryHeader"
import { Link } from "gatsby"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { Helmet } from "react-helmet"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import icon from './resources/info-128.png'; // Correct image import

const CostCalculator = () => {
  const [cost, setCost] = useState({
    paygMonth: 0,
    paygAnnual: 0,

    subscriptionMonth: 0,
    subscriptionAnnual: 0,

    savingMonth: 0,
    savingAnnual: 0,
  })

  const [sliderValue, setSliderValue] = useState(1)

  const paygPrice = 59
  const subscriptionPrice = 1200
  const tierPricesArr = [
    [0, 200, 54],
    [201, 500, 49],
    [501, 1000, 44],
  ]

  const range = [
    { value: 0, step: 1 }, // acts as min value
    { value: 500, step: 1 }, // acts as max value
  ]

  const toUSD = amount => {
    let curr = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount)

    curr = curr.replace("$", "$ ")

    return amount < 0 ? "(" + curr.replace("-", "") + ")" : curr
  }

  useEffect(() => {
    const paygMonth = paygPrice * sliderValue
    const paygAnnual = paygMonth * 12

    let tierPrice = 0
    if (sliderValue > 0) {
      tierPricesArr.map(tiersDetail => {
        if (sliderValue >= tiersDetail[0] && sliderValue <= tiersDetail[1]) {
          tierPrice = tiersDetail[2] * sliderValue
          return
        }
      })
    }

    const subscriptionMonth = parseInt(tierPrice)
    const subscriptionAnnual = subscriptionMonth * 12

    const savingAnnual = (paygMonth - subscriptionMonth) * 12
    const savingMonth = savingAnnual / 12

    setCost({
      ...cost,
      paygMonth,
      paygAnnual,
      subscriptionMonth,
      subscriptionAnnual,
      savingMonth,
      savingAnnual,
    })
  }, [sliderValue])

  return (
    <div className="cost-calc-container">
      <div className="cost-calc-container__inner">
        <h4>
          Calculate your monthly spending and potentials savings with
          Enterprise:
        </h4>

        <p className="cost-calc-container__inner__slider-heading">
          <div>
            1 monthly <br />
            case
          </div>
          <div>
            500 monthly <br /> cases
          </div>
        </p>

        <div className="mb-1 mx-8 block">
          <input
            style={{ width: "100%" }}
            type="range"
            min={1}
            max={500}
            step={1}
            value={sliderValue}
            onChange={event => {
              setSliderValue(event.target.valueAsNumber)
            }}
          />
        </div>

        <div
          className="slider-value"
          style={{
            textAlign: "center",
            position: "relative",
            width: "100px",
            fontSize: "0.7em",
            paddingInline: "0.3rem",
            backgroundColor: "#fff",
            color: "#45adcc",
            fontWeight: "600",
            paddingBlock: "4px",
            transform:
              sliderValue < 500
                ? "translate(-" +
                  (((sliderValue / 500.0) * 100.0) % 100.0) +
                  "%, 0)"
                : "translate(-100%, 0)",
            left: (sliderValue / 500.0) * 100.0 + "%",
          }}
        >
          {sliderValue} monthly <br /> case
          {sliderValue >= 10 && "s"}
        </div>

        <div>
          <div className="cost-calc-container__inner__row">
            <div className="cost-calc-container__inner__row__item"></div>
            <div className="cost-calc-container__inner__row__item">
              Monthly Cost
            </div>
            <div className="cost-calc-container__inner__row__item">
              Annual Cost<sup>1</sup>
            </div>
          </div>

          <div className="cost-calc-container__inner__row">
            <div className="cost-calc-container__inner__row__item">
              Pay As You Go:
            </div>
            <div className="cost-calc-container__inner__row__item">
              {toUSD(cost.paygMonth)}
            </div>
            <div className="cost-calc-container__inner__row__item">
              {toUSD(cost.paygAnnual)}
            </div>
          </div>

          <div className="cost-calc-container__inner__row">
            <div className="cost-calc-container__inner__row__item">
              Enterprise:
            </div>
            <div className="cost-calc-container__inner__row__item">
              {toUSD(cost.subscriptionMonth)}
            </div>
            <div className="cost-calc-container__inner__row__item">
              {toUSD(cost.subscriptionAnnual)}
            </div>
          </div>

          <div className="cost-calc-container__inner__row">
            <div className="cost-calc-container__inner__row__item">
              Savings with Enterprise!
            </div>
            <div className="cost-calc-container__inner__row__item">
              <span className="cost-calc-container__inner__row__type-button">
                {toUSD(cost.savingMonth)}
              </span>
            </div>
            <div className="cost-calc-container__inner__row__item">
              <span className="cost-calc-container__inner__row__type-button">
                {toUSD(cost.savingAnnual)}
              </span>
            </div>
          </div>
        </div>

        <p className="cost-calc-container__inner__footer mt-2">
          {/* (1) It is assumed that all cases treat both arches (maxillary and
          mandibular) for the purpose of this illustration; */}
          (1) Invoices are billed monthly, except for the One-Time Fee for Enterprise customers
          which is billed once at subscription; (2) One-Time Fee of $ 599 will
          be credited to Enterprise customer accounts and consumed first,
          additional payment will only be required for Enterprise customers once
          this amount has been consumed.
        </p>
      </div>
    </div>
  )
}

const PlanNPricingPage = () => {
  const tooltipImg = 
  icon
  return (
    <Layout>
      <GatsbySeo
        title="description"
        description="AlignerBase Pricing: Affordable Clear Aligner Treatment Planning from $65/case. Free trial, no sign up fees, unlimited revisions & multi-user accounts."
      />
      <Helmet>
        <meta charset="utf-8" />
      </Helmet>
      <Seo title={`Free trial and low pricing`} />
      <SecondaryHeader
        headingPosition={"start"}
        title={
          <>
            <h3 style={{ color: "#000" }}>
              Two simple plans, at market-beating prices.
            </h3>
            <p style={{ fontSize: "1rem" }}>
              <Link to="/doctor-registration">
                <span style={{ color: "blue" }}>Sign up today</span>
              </Link>{" "}
              with AlignerBase for a free trial case.
            </p>
          </>
        }
      />
      <div id="plan-n-pricing-page">
        <div className="prices-container">
          <>
            <div className="prices-container__label">
              <div className="prices-container__label__upper"></div>
              <ReactTooltip id="my-tooltip" />
              <div className="prices-container__label__item">
                One-Time Fee
                <img
                  src={tooltipImg} style={{
                    height: '20px'
                  }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="This is an advance payment charged in order to unlock the Enterprise package. This is credited to your account and adjusted against future invoices."
                />
              </div>
              <div className="prices-container__label__item">
                Recurring Fee
                <img
                  src={tooltipImg} style={{
                    height: '20px'
                  }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="AlignerBase does not charge any recurring subscription or platform access fees. Platform access is completely free, and you only pay for treatment plans."
                />
              </div>
              <div className="prices-container__label__item">
                Invoicing
                <img
                  src={tooltipImg} style={{
                    height: '20px'
                  }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Invoices are generated per case for the Pay As You Go package, and at the end of the month for the Enterprise package. Please read the Plans and Pricing policy for further information."
                />
              </div>
              <div className="prices-container__label__item">
                Platform Access{" "}
                <img
                  src={tooltipImg} style={{
                    height: '20px'
                  }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="The AlignerBase Platform is our cloud-based platform that allows you to submit cases and download finalized treatment plans. Sign up today for free access"
                />
              </div>
              <div className="prices-container__label__item">
                Platform Account{" "}
                <img
                  src={tooltipImg} style={{
                    height: '20px'
                  }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="The AlignerBase Platform allows for multiple users to be allowed under a single Enterprise account. "
                />
              </div>
              <div className="prices-container__label__item">
                Account Manager{" "}
                <img
                  src={tooltipImg} style={{
                    height: '20px'
                  }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Account Managers provide tailored and focused support for all potential issues and will be assigned after monthly case load exceeds 200."
                />
              </div>
              <div className="prices-container__label__item">
                Support{" "}
                <img
                  src={tooltipImg} style={{
                    height: '20px'
                  }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="AlignerBase will provide technical, operational or finance related support via Email and Chat to all clients."
                />
              </div>
              <div className="prices-container__label__item">
                Revision Fee{" "}
                <img
                  src={tooltipImg} style={{
                    height: '20px'
                  }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="A Revision is a request to adjust the plan prior to approval - this is during the treatment planning phase. "
                />
              </div>

              <div className="prices-container__label__item">
                Refinement Fee{" "}
                <img
                  src={tooltipImg} style={{
                    height: '20px'
                  }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="A Refinement is a request for a change to the treatment plan (mid-course correction) after a case has been approved and treatment has begun."
                />
              </div>
            </div>

            <div className="prices-card prices-container__payg">
              <div className="prices-container__thick-line"></div>
              <div className="prices-container__inner">
                <h4 className="prices-container__inner__heading">
                  Pay As You Go
                </h4>
                <div className="prices-container__inner__sub-heading">
                  Fixed-rate pricing{" "}
                  <span style={{ fontWeight: "400" }}>(per case)</span>
                </div>
                <div className="price-detail">
                  <div className="price-detail__large">$59</div>
                  {/* <div className="price-detail__small">
                    Both arches / Single arch
                  </div> */}
                </div>
                <div className="price-detail__help-text">
                  Pricing is for both arches and unlimited aligner steps.
                  <sup>1</sup>
                </div>

                <p className="p">
                  Ideal for Individual Dentist and Orthodontic Practices{" "}
                  <span>
                    that have low case volumes and are just starting out with
                    Aligners.
                  </span>
                </p>
                <div className="prices-container__inner__divider"></div>
                <div className="prices-container__inner__item">$ 0</div>
                <div className="prices-container__inner__item">$ 0</div>
                <div className="prices-container__inner__item">Per Case</div>
                <div className="prices-container__inner__item">&#10003;</div>
                <div className="prices-container__inner__item">Single-User</div>
                <div className="prices-container__inner__item">-</div>
                <div className="prices-container__inner__item">
                  Email and Chat
                </div>
                <div
                  className="prices-container__inner__item"
                  style={{ flexDirection: "column" }}
                >
                  <div>$ 0</div>
                  <div
                    style={{
                      fontSize: "0.7em",
                      fontWeight: 400,
                      marginLeft: "4px",
                    }}
                  >
                    (unlimited revisions)
                  </div>
                </div>
                <div className="prices-container__inner__item">
                  1 Free, Subsequent at $ 35
                </div>
              </div>
              <Link to={"/doctor-registration"}>
                <div
                  style={{
                    margin: "1rem 0.8rem",
                    textAlign: "center",
                    borderRadius: "6px",
                    backgroundColor: "#70c3cb",
                    color: "#fff",
                    padding: "0.5rem 1.5rem",
                  }}
                >
                  Sign Up for Trial Case
                </div>
              </Link>
              <div className="prices-container__thick-line-bottom"></div>
            </div>

            <div className="prices-card prices-container__subscription">
              <div className="prices-container__thick-line"></div>
              <div className="prices-container__inner">
                <h4 className="prices-container__inner__heading">Enterprise</h4>
                <div className="prices-container__inner__sub-heading">
                  Tiered pricing{" "}
                  <span style={{ fontWeight: "400" }}>(per case)</span>
                </div>
                <div className="price-detail">
                  <div>
                    <span className="price-detail__price">$54 </span>
                    <span className="price-detail__sub-price">
                      for the first 200 monthly cases
                    </span>
                  </div>
                  <div>
                    <span className="price-detail__price">$49 </span>
                    <span className="price-detail__sub-price">
                      for the next 201 - 500 monthly cases
                    </span>
                  </div>
                  <div>
                    <span className="price-detail__price">$44 </span>
                    <span className="price-detail__sub-price">
                      for the next 501+ monthly cases
                    </span>
                  </div>
                </div>
                <div className="price-detail__help-text">
                  Pricing is for both arches and unlimited aligner steps.
                  <sup>1</sup>
                </div>
                <p className="p">
                  Ideal for Large Practices, Dental Labs and Aligner Companies{" "}
                  <span style={{ fontSize: "12px" }}>
                    that have large case volumes and want minimize their
                    planning costs.
                  </span>
                </p>
                <div className="prices-container__inner__divider"></div>
                <div
                  className="prices-container__inner__item"
                  style={{ flexDirection: "column" }}
                >
                  <div>$599</div>
                  <div
                    style={{
                      fontSize: "0.7em",
                      fontWeight: 400,
                      marginLeft: "4px",
                    }}
                  >
                    (instantly credited back to your account)<sup>2</sup>
                  </div>{" "}
                </div>
                <div className="prices-container__inner__item">$ 0</div>
                <div className="prices-container__inner__item">Monthly</div>
                <div className="prices-container__inner__item">&#10003;</div>
                <div className="prices-container__inner__item">Multi-User</div>
                <div
                  className="prices-container__inner__item"
                  style={{ flexDirection: "column" }}
                >
                  <div>&#10003;</div>
                  <div
                    style={{
                      fontSize: "0.7em",
                      fontWeight: 400,
                      marginLeft: "4px",
                    }}
                  >
                    (for 200+ monthly cases)
                    <sup>3</sup>
                  </div>
                </div>
                <div className="prices-container__inner__item">
                  Email, Chat and Phone<sup>4</sup>
                </div>
                <div
                  className="prices-container__inner__item"
                  style={{ flexDirection: "column" }}
                >
                  <div>$ 0</div>
                  <div
                    style={{
                      fontSize: "0.7em",
                      fontWeight: 400,
                      marginLeft: "4px",
                    }}
                  >
                    (unlimited revisions)
                  </div>
                </div>
                <div className="prices-container__inner__item">
                  1 Free, Subsequent at $ 35
                </div>
              </div>
              <Link to={"/doctor-registration"}>
                <div
                  style={{
                    margin: "1rem 0.8rem",
                    textAlign: "center",
                    borderRadius: "6px",
                    backgroundColor: "#70c3cb",
                    color: "#fff",
                    padding: "0.5rem 1.5rem",
                  }}
                >
                  Sign Up for Trial Case
                </div>
              </Link>
              <div className="prices-container__thick-line-bottom"></div>
            </div>
          </>
          <div className="prices-container__cost-calculator hidden lg:flex">
            <CostCalculator />
          </div>
        </div>

        <div className="prices-container__cost-calculator lg:hidden flex">
          <CostCalculator />
        </div>

        <p style={{ fontSize: "0.7em", marginTop: "3rem" }}>
          (1) This pricing is for both arches / or a single arch (may be
          maxillary or mandibular) and not limited by the number of aligner
          steps required per case; (2) A One-Time Fee of USD $599 for Enterprise
          customers is billed at the beginning only and credited to Enterprise
          customer accounts immediately; this amount will be fully adjustable
          towards invoices and additional payment will only be required once
          this amount has been fully consumed; (3) An Account Manager will be
          assigned to Enterprise customers the month after their monthly case
          load exceeds 200 - for example, if a customer's case load is 201 in
          June, an Account Manager will be assigned July onwards; (4) Phone
          Support will be made available to Enterprise customers the month after
          their monthly case load exceeds 200 - for example, if a customer's
          case load is 201 in June, Phone Support will be made available July
          onwards.
        </p>

        <p style={{ fontSize: "0.7em", marginTop: "1rem" }}>
          For further information on plans and pricing, kindly refer to the{" "}
          <Link to="/plan-and-pricing-policy">
            <span style={{ color: "blue" }}>Plans and Pricing policy</span>
          </Link>{" "}
          and the Alignerbase LLC{" "}
          <Link to="/terms-of-service">
            <span style={{ color: "blue" }}>Terms and Conditions.</span>
          </Link>
        </p>
      </div>
    </Layout>
  )
}

export default PlanNPricingPage
